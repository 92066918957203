import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

const DEBOUNCE_TIMING = 300

export default class extends Controller {
  static targets = [
    "filterForm",
    "perPageInput"
  ]

  connect() {
    this.frame = this.element.querySelector("turbo-frame")

    this.frame.addEventListener("turbo:before-frame-render", this.handleContentFade.bind(this))

    this.debouncedTurboVisit = debounce((href, options = {}) => Turbo.visit(href, options), DEBOUNCE_TIMING)
    this.debouncedSubmitFilterForm = debounce(() => this.submitFilterForm(), DEBOUNCE_TIMING)
  }

  disconnect() {
    this.frame.removeEventListener("turbo:before-frame-render", this.handleContentFade.bind(this))
  }

  triggerLoading() {
    const datagrid = this.element
    const tableBody = datagrid.querySelector("tbody")
    const rows = tableBody.querySelectorAll("tr")
    const template = datagrid.querySelector("template")

    rows.forEach(row => { row.classList.add("collapse") })

    tableBody.innerHTML += template.innerHTML
  }

  handleContentFade(event) {
    const oldFrame = this.frame
    const newFrame = event.detail.newFrame

    const oldContent = oldFrame.querySelector("tbody")

    if (oldContent) {
      oldContent.classList.add("fade-out")
    }

    const newContent = newFrame.querySelector("tbody") || newFrame
    newContent.classList.add("fade-in")
  }

  sortColumns(e) {
    e.preventDefault()

    this.triggerLoading()

    const turboFrame = this.element.querySelector("turbo-frame")
    const turboFrameId = turboFrame.id

    this.debouncedTurboVisit(e.currentTarget.href, { frame: turboFrameId })
  }

  changeFilters(e) {
    if (e.target.form == this.filterFormTarget) {
      this.triggerLoading()

      this.debouncedSubmitFilterForm()
    }
  }

  submitFilterForm() {
    this.filterFormTarget.requestSubmit()
  }

  changePerPage(e) {
    const newValue = e.target.value

    if (newValue != this.perPageInputTarget.value) {
      this.perPageInputTarget.value = newValue
      this.perPageInputTarget.removeAttribute("disabled")

      this.triggerLoading()
      this.debouncedSubmitFilterForm()
    }
  }

  selectionsChanged(e) {
    this.dispatch(
      "selectionsChanged", { detail: e.detail }
    )
  }
}
