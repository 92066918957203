/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()

// TODO: Remove this once all Javascript is moved into Webpack modules
global.Rails = Rails

// Load Bugsnag before any other libraries to catch as many errors as possible
// Styles
import "application.css.less"

// These need to be required first since they are dependent on each other and a bunch of other stuff
// is dependent on them
import "jquery"
import "bootstrap/dist/js/bootstrap"


// Libraries
import "jquery.turbolinks/src/jquery.turbolinks"
import "bootstrap-colorpicker"
import "bootstrap-datepicker"
import "bootstrap-toggle"
import "@open-xchange/bootstrap-tokenfield"
import "chartjs"
import "clipboard"
import "datatables.net-bs"
import "select2"
import "jquery-ui/ui/widgets/sortable"
import "prismjs-1.19/prism"
import "calentim/calentim"
import "flowbite"

import autosize from "autosize"
$.fn.extend({autosize: autosize})

HTMLCollection.prototype.forEach = function(callbackFn, thisArg) {
  Array.from(this).forEach(callbackFn, thisArg)
}

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

// Our code :)
import "../src/utils"
import "controllers"
import setTimezoneCookie from "../src/user_agent_timezone"
setTimezoneCookie()

// Import individual Javascript files (not modules)
// TODO: These should be refactored away
import "../src/google_analytics.js"
import "../src/canny.js"
import "../src/jquery.datatableerror.js"
import "../src/jquery.datatablerealtime.js"
import "../src/jquery.usertimeouterror.js"
import "../src/jquery.readyselector.js"
import "../src/org/agents/binaries.js"
import "../src/org/binaries/index.js"
import "../src/event_types.js"

// Only collect Snowplow, Bugsnag, and Amplitude data in production
if (`${process.env.NODE_ENV}` == "production") // eslint-disable-line
{
  import("../src/snowplow_tracker.js")
  import("../src/bugsnag.js")
  import("../src/amplitude.js")
}

$(function() {
  $("[data-toggle='tooltip']").tooltip()
})

$(document).ajaxComplete(function() {
  // Required to refresh Bootstrap tooltips in DataTables that are loaded with ajax
  $("[data-toggle='tooltip']").tooltip()
})

$(document).on("select2:open", (event) => {
  const target = event.target
  if (target) {
    const id = target.id || target.name
    const selector = document.querySelector(`input[aria-controls*='${id}']`)
    if (selector) {
      selector.focus()
    }
  }
})

document.addEventListener("turbo:frame-missing", (event) => {
  try {
    const { detail: { response } } = event

    if (response.status === 401) {
      window.location.reload()
    }
  } catch(error) {
    Bugsnag.notify(error)
  }
})
