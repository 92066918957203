import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["installationMethod", "installSelect", "wizardInstructionsInitial", "wizardInstructionsCollapsedPanel"]

  connect() {
    $(this.installSelectTarget).select2({
      theme: "bootstrap",
      placeholder: "Select installation method",
      width: "100%",
    })

    $(this.installSelectTarget).on("select2:select", e => { this.showInstallationMethod(e) })
  }

  showInstallationMethod(e) {
    this.wizardInstructionsInitialTarget.classList.add("hidden")
    this.wizardInstructionsCollapsedPanelTarget.classList.remove("hidden")

    const selectedValue = e.target.value

    this.installationMethodTargets.forEach(panel => {
      if (panel.dataset.installMethod === selectedValue) {
        panel.classList.remove("hidden")
      } else {
        panel.classList.add("hidden")
      }
    })
  }
}
